// Bootstrap functions (must include before using them)
@import "~bootstrap/scss/functions";

// Bootstrap variable overrides
// See /node_modules/boostrap/scss/_variables.scss for a list of variables
$statusmatcher-color: #A00;
$statusmatcher-color-light: #ECC;
$statusmatcher-grey: #C4C4C4;

$navbar-light-brand-color: $statusmatcher-color;
$navbar-light-brand-hover-color: shade-color($statusmatcher-color, 25%);

// Required Bootstrap
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Optional Bootstrap
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// StatusMatcher
body {
  padding-top: 70px;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.guide-highlight {
  font-weight: bold;
  color: $statusmatcher-color;
}

/*
    Report List Item
 */
.report-list-item-middle-box {
  flex: 1;
}

.report-list-item-right-box {
  width: 150px;
}

.instructions {
  white-space: pre-wrap;
}

/*
	Result Box
*/

.result-box {
  border: 1px solid transparent;
  border-radius: 8px;
  width: 85px;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.result-box-match,
.result-box-challenge {
  color: #333333;
  background-color: $statusmatcher-color-light;
  border-color: shade-color($statusmatcher-color-light, 5%);
}

.result-box-deny,
.result-box-other {
  color: #333333;
  background-color: $statusmatcher-grey;
  border-color: shade-color($statusmatcher-grey, 5%);
}

/*
  Avatar
 */
img.avatar {
  max-width: 50px;
  max-height: 50px;
}

img.avatar-small {
  max-width: 36px;
  max-height: 36px;
}

/*
	Status Box
*/
.status-box {
  border-width: 1px;
  border-style: solid;
}

span.status-box {
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-border-radius);
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

div.status-box-container {
  margin: 0 auto;
  max-width: 100px;
  padding: 6px;

  &.selected {
    border-style: solid;
    border-width: 4px;
    border-color: $statusmatcher-color;
    padding: 2px;
    border-radius: 6px;
    font-weight: bold;
  }
}

div.status-box {
  padding: 2px;
  border-radius: 6px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: 45px;
}

/*
	Comments
*/
.comment-body {
  white-space: pre-line;
}

/*
  Inline Commands
 */
.inline-command {
  display: inline-block;
  margin-left: 15px;
}

/*
  Entity Table
 */
.entity-table {
  table-layout: fixed;
  font-size: 12px;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-group {
    button {
      font-size: 10px;
    }
  }
}

/*
  Miscallaneous
 */

.badge {
  background-color: $statusmatcher-color-light !important;
}